<template>
  <div class="player">
    <div class="backup-alert" v-if="(current.name == 'backup')">
      <!-- span text red -->
      <span class="red--text"><b> You are listening to our backup streams</b></span> <br />
      <span class="red--text"><b> Songs titles, artist and artwork may not be accurate.</b></span>
    </div>
    <div class="player-info">
      <div class="player-info-img hidden-sm-and-down">
        <transition name="slide-fade" mode="out-in">
          <v-img :key="metadata.art" :src="metadata.now.art"></v-img>
        </transition>
      </div>
      <div class="track-info-text mt-4">
        <transition name="slide-fade" mode="out-in">
          <span :key="current.name == 'backup' ? track.title : metadata.now.title"  class="track-title">{{ current.name == 'backup' ? track.title : metadata.now.title }}</span>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <span :key="current.name == 'backup' ? track.artist : metadata.now.artist" class="track-artist">{{
            current.name == 'backup' ? track.artist : metadata.now.artist
          }}</span>
        </transition>
      </div>
    </div>    
    <br />
    <div class="player-controls">
      <div class="controls-status">
        <span v-if="(!playing && !buffering && !playerError)">
          <v-icon @click="play()" size="50">mdi-play-circle-outline</v-icon>
        </span>
        <span v-else-if="playing && !buffering">
          <v-icon @click="stop()" size="50">mdi-stop-circle-outline</v-icon>
        </span>
        <span v-else-if="buffering">
          <v-progress-circular
            indeterminate
            color="primary"
            size="50"
          ></v-progress-circular>
        </span>
        <span v-else-if="(playerError == true)">
          <v-icon @click="play()" size="50">mdi-alert</v-icon>
        </span>
      </div>
      <div class="controls-volume">
        <v-icon size="50">mdi-volume-high</v-icon>
        <input
          type="range"
          name="volume"
          v-model="volume"
          min="0"
          max="1"
          step=".01"
          @input="HandleVolume"
        />
      </div>
      <div class="contols-options">
        <!-- Options meny -->
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon size="35">mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="hover-pointer">
              <v-list-item-title @click="enableNotif()" class="cursor-pointer"
                >Enable Notification</v-list-item-title
              >
            </v-list-item>
            <v-list-item class="hover-pointer">
              <v-list-item-title @click="disableNotif()" class="cursor-pointer"
                >Disable Notification</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="control-sources">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon size="35">mdi-playlist-music-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="source in streams"
              :key="source.name"
              class="hover-pointer"
            >
              <v-list-item-title
                @click="changeSource(source)"
                class="cursor-pointer"
                >{{ source.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "cr-player",
  props: ["metadata"],
  data() {
    return {
      track: {
        title: "Hello!",
        artist: "Cloudsdale Radio",
        art: "https://cdn.nishikino.xyz/cloudsdaleradio/CR_OFFLINE.png",
      },
      streams: [
        {
          name: "Main Stream",
          url: "https://live.cloudsdaleradio.com/cloudsdaleradio.mp3",
        },
        {
          name: "High Quality Stream",
          url: "https://live.cloudsdaleradio.com/cloudsdaleradio.off",
        },
        // {
        //   name: "Low Quality Stream",
        //   url: "https://jp-broadcaster.cloudsdaleradio.com/cloudsdaleradio64.mp3",
        // },
        {
          name: "backup",
          url: "https://live.cloudsdaleradio.com/backup",
        }
      ],
      index: 0,
      current: {},
      player: new Audio(),
      playing: false,
      buffering: false,
      playerError: false,
      volume: 50,
      notificationPermissions: false,
      notifEnabled: false,
    };
  },
  methods: {
    getMetadata() {
        if(!this.current) {
          return console.log("No streams available retying in 5 seconds")
        }
    },
    updateChrome() {
      console.log("Chrome metadata was not updated.")
      //   Google meta session
      // navigator.mediaSession.metadata = new window.MediaMetadata({
      //         title: this.metadata.title,
      //         artist: this.metadata.artist,
      //         album: "Cloudsdale Radio",
      //         artwork: [
      //           {
      //             src: this.metadata.art,
      //             sizes: "512x512",
      //             type: "image/png",
      //           },
      //         ],
      //       });
    },
    updateNotification(data) {
      // Notification
      if (!this.notifEnabled) return;
            if (!this.notificationPermissions) return;
            const notifText = `${data.title} - ${data.artist}`;
            const notifTitle = "Cloudsdale Radio";
            const notifArt =
              data.art ||
              "https://cdn.nishikino.xyz/cloudsdaleradio/CR_OFFLINE.png";
            const notif = new Notification(notifTitle, {
              body: notifText,
              icon: notifArt,
            });
            notif.onclick = () => {
              window.focus();
              notif.close();
            };
            return notif;
    },
    play() {
      this.buffering = true;
      this.player.volume = localStorage.getItem("CR_volume") || 0.5;
      this.player.preload = "auto";
      this.player.play()
      .catch((err) => {
        this.playerError = true;
        this.buffering = false;
        console.log(err);
        console.log("The player goofed... falling back")
        this.player.play("https://live.cloudsdaleradio.com/backup");
      });
      this.player.addEventListener("playing", () => {
        this.playing = true;
        this.buffering = false;
      })
    },
    stop() {
      this.player.pause();
      this.playing = false;
    },
    HandleVolume() {
      this.player.volume = this.volume;
      localStorage.setItem("CR_volume", this.volume);
    },
    changeSource(source) {
      // find the index of the stream in sources
      this.index = this.streams.findIndex((s) => s.name === source.name);
      // swap current, for new changed stream
      this.current = source;
      // set the player source to the new stream
      this.player.src = source.url;
      // play the new stream
      this.play();
    },
    enableNotif() {
      this.notifEnabled = true;
      localStorage.setItem("CR_Notifs", this.notifEnabled);
    },
    disableNotif() {
      this.notifEnabled = false;
      localStorage.setItem("CR_Notifs", this.notifEnabled);
    },
    // get metadata for backup broadcaster
    getBackupData() {
      axios.get(`${process.env.VUE_APP_PLAYER}/rescue`)
      .then((res) => {
        this.track = res.data;
      })
    }
  },
  created() {
    this.buffering = true;
  },
  mounted() {
    this.buffering = false;
    this.current = this.streams[this.index];
    this.player.src = this.current.url;
    this.volume = localStorage.getItem("CR_volume") || 50;
    // this.notifEnabled = localStorage.getItem("CR_Notifs");
    this.notifEnabled = false;
    Notification.requestPermission().then((result) => {
      result === "granted"
        ? (this.notificationPermissions = true)
        : (this.notificationPermissions = false);
    });
    // Update chrome and notification based on timing
    setInterval(() => {
      if(this.current.name == "backup") this.getBackupData()
      // this.updateChrome();
      this.updateNotification(this.metadata);
    }, 5000);
  },
  beforeDestroy() {
    if (!this.playing) return;
    this.player.pause();
  },
};
</script>

<style scoped>
.player {
  max-width: fit-content;
  max-height: fit-content;
}
.player-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: max-content;
}
.player-info-img {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.player-info-img img {
  width: 100%;
  height: 100%;
}
.track-info-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-left: 20px;
}
.track-title {
  font-size: 2rem;
  font-weight: bold;
}
.track-artist {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(36, 36, 36);
}
.player-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: max-content;
}
.controls-status {
  display: flex;
  justify-content: center;
  align-items: center;
}
.controls-volume {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.hover-pointer {
  background: #fff;
}
.hover-pointer:hover {
  background: #929292;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
