<template>
  <v-container>
    <div class="header">
      <img
        src="https://cdn.nishikino.xyz/cloudsdaleradio/CRlogo.png"
        width="50%"
      />
    </div>
    <div class="spacer"></div>
    <v-layout row wrap>
      <v-col xs="12" sm="12" md="12" lg="6" xl="6">
        <v-card>
          <v-card-text v-if="betaPlayer == 'true'">
            <BetaPlayer :streams="streams" :metadata="metadata" />
          </v-card-text>
          <v-card-text v-else>
            <Player />
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col xs="12" sm="12" md="12" lg="6" xl="6">
        <v-card flat tile text style="background-color: rgba(255,255,255, 0)">
          <v-btn
            align="center"
            justify="center"
            class="mx-4"
            @click="openPopup"
          >
          popup
          </v-btn>
          <v-dialog
          v-model="RequestDialog"
          max-width="900">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            align="center"
            justify="center"
            class="mx-4"
            v-bind="attrs"
            v-on="on">
            Request
          </v-btn>
          </template>
          <v-card>
          <v-card-title>Song Request</v-card-title>
          <v-card-text>
            Requests are limited to 1 per 15 minutes, and are disabled when the a DJ is streaming.
            <!-- Last Requested: {{ lastRequest.artist || "Cloudsdale Radio"}} - {{ lastRequest.title || "From The Sky"}} ({{ lastRequest.time }}Minutes ago) -->
              <iframe src="https://broadcaster.cloudsdaleradio.com/public/cloudsdale_radio/embed-requests?theme=light" frameborder="0" allowtransparency="true" style="width: 100%; min-height: 850px; border: 0;"></iframe>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
        v-model="historyDialog"
        max-width="900">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          align="center"
          justify="center"
          class="mx-4"
          v-bind="attrs"
          v-on="on">
          History
        </v-btn>
        </template>
        <v-card>
        <v-card-title>History</v-card-title>
        <v-card-text>
          <iframe src="https://broadcaster.cloudsdaleradio.com/public/cloudsdale_radio/embed-history?theme=light" frameborder="0" allowtransparency="true" style="width: 100%; min-height: 850px; border: 0;"></iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
        </v-card>
      </v-col>
    </v-layout>
    <!-- <div class="spacer"></div>
    <div class="youtube text-center">
      <h3>YouTube Live</h3>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/5PgJt-RLhhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->
  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus';
import Player from "@/components/Players/Players.vue"
import BetaPlayer from "@/components/Players/BetaPlayer.vue"
export default {
  name: "Home-Page",
  props: ["streams", "metadata"],
  components: {
    Player,
    BetaPlayer
  },
  data() {
    return {
      betaPlayer: '',
      historyDialog: false,
      RequestDialog: false,
      lastRequest: {
        artist: '',
        title: '',
        time: ''
      },
      listens: [
        // {
        //   name: "Navigateur",
        //   link: { name: "NavAudio"},
        // },
        {
          name: "Request",
          link: { name: "Request"},
        },
        {
          name: "history",
          link: { name: "history"},
        },
      ]
    }
  },
  beforeMount() {
    if(!localStorage.getItem("CR_betaPlayer")) this.betaPlayer = true
    this.betaPlayer = localStorage.getItem("CR_betaPlayer")
  },
  methods: {
    openPopup() {
      window.open(
        '/popup',
        '_blank',
        'width=648,height=404'
      );
    }
  },
};
const handlePlayerChange = function(enableBeta) {
  console.log(`EventBus: ${enableBeta}`)
  this.betaPlayer = enableBeta
}
EventBus.$on('betaPlayer', handlePlayerChange)
</script>

<style>
/* center image in .header in the middle of the screen */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* spacer */
.spacer {
  height: 10vh;
}
</style>