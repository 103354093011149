<template>
    <div class="No-wordpress-lol">
        <v-container>
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-title>
                            <h1 class="display-1">Ahah! no.</h1>
                        </v-card-title>
                        <v-card-text>
                            <p class="body-1">We are not using wordpress.</p>
                            <p>If you are a station staff member, you are looking in the wrong place.</p>
                            <p>If you are not a station staff member What the hell you are doing here? btw, IP is logged
                                :) ({{ clientIp }})</p>
                            <!-- Display user IP using cloudflare layout -->
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title>
                            <h1 class="display-1">This is just wasted space.</h1>
                        </v-card-title>
                        <v-card-text>
                            <p class="body-1">Yeah we are bored here. wwww</p>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-1">
                        <v-card-title>
                            <h1 class="headline">ええ.</h1>
                        </v-card-title>
                        <v-card-text>
                            <p class="body-1">Just another one for shit and giggles.</p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="5" align="center" justify="center">
                    <v-card>
                        <v-img src="https://cdn.asthriona.com/i/2022/10/_pn_221011_0858PM05285.png" contain></v-img>
                        <v-card-title>
                            Okay, so here is a picture from our offices window.
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// import axios from "axios";
export default {
    name: "wp-admin",
    data() {
        return {
            clientIp: ""
        }
    },

}
</script>