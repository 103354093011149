<template>
  <div>
    <iframe
      :src="`https://broadcaster.cloudsdaleradio.com/public/cloudsdale_radio/embed?theme=light`"
      frameborder="0"
      allowtransparency="true"
      style="width: 100%; min-height: 150px; border: 0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "CloudsdaleRadio",
  data() {
    return {
      theme: "dark",
    };
  },
  beforeMount() {
    // check actual hours
    const hours = new Date().getHours();
    if (hours > 18 || hours < 6) {
      this.theme = "dark";
      this.$vuetify.theme.dark = true;
    } else {
      this.theme = "light";
      this.$vuetify.theme.dark = false;
    }
  },
};

</script>