<template>
  <div class="player-popup">
    <div class="player-popup-content">
      <div class="player-popup-content-close"></div>
      <v-card>
          <v-card-text v-if="betaPlayer == 'true'">
            <BetaPlayer :metadata="metadata" />
          </v-card-text>
          <v-card-text v-else>
            <Player />
          </v-card-text>
        </v-card>
    </div>
  </div>
</template>

<script>
import Player from "@/components/Players/Players.vue";
import BetaPlayer from "@/components/Players/BetaPlayer.vue";
export default {
  name: "popup-page",
  props: ["metadata"],
  data() {
    return {
      betaPlayer: '',
    }
  },
  components: {
    Player,
    BetaPlayer,
  },
  mounted() {
    // TODO: Get metadata from the main window
    // TODO: display art cover as window background
    // TODO: display metadata as window title "Cloudsdale Radio - ${Artist} - ${Title}"
    
  },
  beforeMount() {
    if(!localStorage.getItem("CR_betaPlayer")) this.betaPlayer = false
    this.betaPlayer = localStorage.getItem("CR_betaPlayer")
  },
}
</script>

<style scoped>
.middle {
    position: absolute;
    width: 100%;
    top: 25%;
}
#popup-player {
    overflow: hidden;
}
</style>