<template>
  <div class="about">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <div class="csg-history light-background text-center">
            <v-img src="https://cdn.nishikino.xyz/cloudsdaleradio/CRlogo.png" alt="Cloudsdale Radio's logo" height="150" contain></v-img>
          <div class="page-title">
            <span class="display-2">About {{ names.cr }}</span>
          </div>
          <div class="about-station-history text-center">
            <span class="display-1">What's our story?</span>
            <p>
              <b>{{ names.cr }}</b> was founded in 2013 by <b>{{ names.shd }}</b> and was a station broadcasting music made by "My Little Pony: Friendship is Magic's" fans. also known as The Brony Fandom. <br />
              The station moved a lot, from being an independent project, to forming it's own Broadcasting company <b>{{ names.csg }}</b> in 2015. <br />
              In 2020, <b>{{ names.csg }}</b> took the decision to progressively shutdown <b>{{ names.cr }}</b>, in order to make a smooth transition from the brony fandom to their new Anime music based station <b>{{ names.afm }}</b>. <br />
              Few month after the launch of <b>{{ names.afm }}</b>, <b>{{ names.csg }}</b> shutdown <b>{{ names.cr }}</b> and after a few other month, <b>{{ names.afm }}</b> had to shutdown aswell due to the lack of finances. <br />
              in 2022, <b>{{ names.csg }}</b> was bought by <b>{{ names.bfg }}</b> then relaunched in 2024. <br />
              Why you ask? Because we can :)
            </p>
          </div>
          </div>
        </v-col>
        <!-- <v-col cols="10" class="light-background">
            <h1 class="text-left">Q&A</h1>
            <ol>
              <li>
                <b>Q:</b> <i>Why did the station shutdown?</i>
                <br />
                <b>A:</b>
                <i
                  >In the first place, the station has been shutdown, due to a
                  lake of motivation of the old owners, and them leaving the
                  brony fandom</i
                >
              </li>
              <li>
                <b>Q:</b>
                <i>Is the <b>{{ names.nsh }}</b> or <b>{{ names.ytd }}</b> teams part of the Brony fandom?</i>
                <br />
                <b>A:</b> Some of us used to be, but not anymore no.
              </li>
              <li>
                <b>Q:</b> <i>Why did you buy the station?</i>
                <br />
                <b>A:</b> We like the idea of a broacasting network and all the
                management around it, this one was a good opportunity to get
                into that kind of business. Plus, the founder of
                {{ names.csg }} and the CEO of {{ names.nsh }}, know each other
                well since they both lives in the same area in Tokyo. We at
                {{ names.nsh }} thought that would be a great oportunity to
                learn new things, and aquire new skills, so instead of leaving
                this project for dead, we decided to buy it and bring it back.
              </li>
              <li>
                <b>Q:</b>
                <i>Will you be adding more stations to the network?</i>
                <br />
                <b>A:</b> We are currently working on a new station, but we
                can't say much about it yet.
              </li>
              <li>
                <b>Q:</b>
                <i>Will you be adding more features to the website?</i>
                <br />
                <b>A:</b> Yes, We are currently working on the new players and
                their features, and focusing on the basics of the station, like
                the front-end, Songs Meta data, HLS Streams support, and more.
              </li>
              <li>
                <b>Q:</b> <i>Will take part in brony event (Like bronyCon)?</i>
                <br />
                <b>A:</b> No, we do not plan on taking part in any of those, as
                said before, we are not part of the brony fandom anymore, and
                some of us never was.
              </li>
              <li>
                <b>Q:</b> <i>Will you be adding more staff members?</i>
                <br />
                <b>A:</b> At the moment none of <b>{{ names.bfg }}</b
                >, <b>{{ names.nsh }}</b> nor <b>{{ names.ytd }}</b> is in need
                of staff, we'll talk about it on our SNS and websites if we ever
                need more staff.
              </li>
              <li>
                <b>Q:</b> <i>Will you be adding more languages?</i>
                <br />
                <b>A:</b> Yes, This feature is on our roadmap, but we can't say
                when it will be added.
              </li>
              <li>
                <b>Q:</b> <i>How can I listen to the station?</i>
                <br />
                <b>A:</b> You can listen to the station on our website, or via
                any media player by downloading the stream files, availiable in
                .m3u and .pls.
              </li>
              <li>
                <b>Q:</b> <i>Will you be adding more stream formats?</i>
                <br />
                <b>A:</b> Yes, We are currently working on adding HLS streams,
                and OGG (Vorbis) and ACC+ should be enabled soon.
              </li>
              <li>
                <b>Q:</b> <i>Will there be shows?</i>
                <br />
                <b>A:</b> Sadly no. We are 100% focused on music, something you can listen to, when you are working, for example.
              </li>
              <li>
                <b>Q:</b><i>I found a bug, Where can I report it?</i>
                <br />
                <b>A:</b> Please send us an email at <a :href="'mailto:developement@Stellar-corp.net'"> developement@Stellar-corp.net </a>
              </li>
              <li>
                <b>Q:</b><i>Do you have a Discord server, Telegram channel or Slack?</i>
                <br />
                <b>A:</b> No, <b>{{ names.bfg }}</b> don't use Discord, unlike <b>{{ names.ytd }}</b>, But this server is only used for <b>{{ names.ytd }}</b>'s direct projects. <br />
                All our teams from <b>{{ names.nsh }}</b>, <b>{{ names.ytd }}</b> and <b>{{ names.bfg }}</b> Uses <a href="https://line.me" target="_blank" rel="noopener noreferrer">LINE</a> for communication.
              </li>
              <li>
                <b>Q:</b><i>Is AnimeFM coming back too?</i>
                <br />
                <b>A:</b> Yes! In the near future, but for now, if you need Japanese music, or Anime Music, there is plenty of other stations that you can listen to. until we launch our own.
              </li>
              <li>
                <b>Q:</b><i>Is the old staff coming back?</i>
                <br />
                <b>A:</b> as we speak, no. {{ names.csg }} has been brought with no staff still working for it, and as a dead project.
              </li>
              <li>
                <b>Q:</b><i>Who are you? and who is your staff?</i>
                <br />
                <b>A:</b> We are <b>{{ names.ytd }}</b> a group of friends, who like to work on project like this one, and who love more than anything else a good challenge. <br />
                All members are formerly from Tokyo, but now we are all scattered across Japan. <br />
              </li>
              <li>
                <b>Q:</b><i>英語上手ですね！</i>
                <br />
                <b>A:</b> 有賀とございます！！！！
              </li>
            </ol>
          </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About-page",
  data() {
    return {
      names: {
        shd: "SiriusHD",
        nsh: "Nishikino Networks",
        ytd: "Stellar Corporation",
        bfg: "Sora Media Network Inc.",
        csg: "Cloudsdale Group",
        cr: "Cloudsdale Radio",
        afm: "AnimeFM",
      },
    };
  },
};
</script>

<style scoped>
.about {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.light-background {
  background-color: #f5f5f5;
  padding: 10px;
  margin-top: 10px;
}
li {
  margin-bottom: 10px;
}
</style>
