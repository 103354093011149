<template>
    <div class="player">
        <NewPlayer />
    </div>
</template>

<script>
import NewPlayer from "@/components/Players/BetaPlayer.vue";
export default {
    name: "player-page",
    components: {
        NewPlayer
    }
}
</script>