<template>
  <div :class="`TheFuckingNavBar ${this.$route.name == 'Popup' ? 'hidden' : 'not-hidden'}`">
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img alt="Cloudsdale Radio logo" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
            src="https://cdn.nishikino.xyz/cloudsdaleradio/CRlogo.png" width="200" :to="{ name: 'Home' }" />
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home' }"><v-btn text>Home</v-btn></router-link>
        <router-link :to="{ name: 'NavAudio' }"><v-btn text>Listen</v-btn></router-link>
        <!-- <router-link :to="{ name: 'Home'}"><v-btn text>Contact</v-btn></router-link> -->
        <router-link :to="{ name: 'About' }"><v-btn text>About</v-btn></router-link>
        <!-- <router-link :to="{ name: 'Request'}"><v-btn text>Request</v-btn></router-link> -->
        <!-- <router-link :to="{ name: 'API'}"><v-btn text>API</v-btn></router-link> -->
      </div>

      <v-spacer></v-spacer>

      <v-btn text>
        <span><v-icon>mdi-account</v-icon> {{ metadata.listeners || 0 }}</span>
      </v-btn>
      <router-link :to="{ name: 'history' }" class="shrink mt-1 hidden-sm-and-down">
        <!-- <v-icon>mdi-play-circle-outline</v-icon> -->
        <div class="nowPlaying" v-if="!metadata.now.title">
          <span class="mr-2">Loading...</span>
        </div>
        <v-tooltip :text="`${metadata.now.artist} - ${metadata.now.title}`" location="bottom">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="mr-4" text><img class="mr-2 mini-art" :src="metadata.now.art" height="32" />
              <span class="d-inline-block text-truncate" style="max-width: 200px;">{{ metadata.now.artist }} - {{
                metadata.now.title }}</span></v-btn>
          </template>
        </v-tooltip>
      </router-link>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "TheNavBar",
  props: ["metadata"],
}
</script>

<style scoped>
.imgNav {
  margin-right: 10px;
  border-radius: 25%;
  border-style: solid;
  border-width: 2px;
  border-color: aliceblue;
}
</style>